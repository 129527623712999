<template>
  <div> 
    <div class="process_detail-page" v-title data-title="消息详情"></div>
    <h2>{{data.title}}</h2>
    <p>发送人：{{data.send_name}}</p>
    <div class="content">
      {{data.content}}
    </div>
  </div>
</template>

<script>

import request from "../../plugin/request";
export default {
  data() {
    return{
      uid:'',
      class_notice_id:'',
      data:''
    }
  },
  mounted() {
    let currentQuery = this.$route.query;
    this.uid = currentQuery.uid;
    this.class_notice_id = currentQuery.class_notice_id;
    this.$nextTick(() => {
      this.details()
     })
  },
  methods: {
    async details() {
       const data=await request.post('app/notice/noticeDetails',{
        user_id :this.uid,
        class_notice_id:this.class_notice_id
       })
       console.log(data)
       this.data=data.data
    }
  }
}
</script>

<style lang="less">
  body {
    padding:.4rem;

    h2 {
      padding:0;
      margin:0;
      font-size: .48rem;
      font-weight: bold;
    }

    p {
      padding:0;
      margin:0;
      margin-top:.21rem;
      font-size: .35rem;
      color: #D8D8D8;

    }
    .content {
      margin-top:.53rem ;
      font-size:.4rem;
    }
  }

</style>