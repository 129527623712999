import axios from 'axios'
import store from '@/store'
import {
    getToken
} from '../util/auth'
import Vue from 'vue'
Vue.prototype.$axios = axios
// create an axios instance
const service = axios.create({
    // baseURL: 'http://test.v2.kytapp.com/api',
    baseURL: 'https://xxzs.kytapp.com/api',
   
    timeout: 5000 // request timeout
})
// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['token'] = getToken()
        // config.headers['token'] = '3aaecf2e-86a5-4d3e-b821-91d76c7ce3ea'
        config.headers['device'] = 'mobile'
        // config.headers['channel'] = 'h5'

        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug 
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data

        return res
    },
    error => {
        e.reject(error)
    }
)

export default service